import React, { Component } from 'react';
import Tree from 'react-d3-tree';
import { GetData, PostData } from './../../../api/service';
import { Card, Typography, Grid, CardActions, TextField, Button, withStyles, CardHeader, CardContent } from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert';
import { red } from '@material-ui/core/colors';

// const myTreeData = [
//     {
//         name: 'Me',
//         attributes: {
//             ID: 'YDS000001',
//             level: '3',
//         },
//         children: [
//             {
//                 name: 'Pranjal Saikia',
//                 attributes: {
//                     ID: 'YDS000002',
//                     level: '2',
//                 },
//                 _collapsed: true,
//                 children: [
//                     {
//                         name: 'Chandan Nath',
//                         attributes: {
//                             ID: 'YDS000003',
//                             level: '1',
//                         },
//                     },
//                     {
//                         name: 'Add New',
//                     },
//                 ],
//             },
//             {
//                 name: 'Brikudor Seal',
//                 attributes: {
//                     ID: 'YDS000004',
//                     level: '1',
//                 },
//             },
//         ],
//     },
// ];

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
    titleColor: {
        color: '#2196F3'
    },
    formControl: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    mulsel: {
        zIndex: 5
    },

    mulsel1: {
        zIndex: 6
    },
    root: {
        
        backgroundColor: 'none'
    }
})

const containerStyles = {
    width: '100%',
    height: '50vh',
}

class TreeComponent extends Component {
    state = {
        isLoading: true,
        data: [],
        node_count: 0,
        name: '',
        mno: '',
        balance: 0,

    }

    __fetchTreeData = (user_id) => {
        this.setState({
            isLoading: true,
            name: '',
            mno: ''
        })
        GetData(`/${this.props.apikey}/${this.props.username}/${user_id}/gettree`)
            .then((resp) => {

                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp,
                        isLoading: false,
                        node_count: resp[0]['children'].length
                    })
                } else {
                    this.setState({
                        isLoading: true
                    })
                }
            })

        GetData(`/${this.props.apikey}/${this.props.username}/${user_id}/getuserbalance`)
            .then((resp) => {


                this.setState({
                    balance: resp,
                })

            })
    }

    onClick = (e) => {

    }

    __getData = () => {
        this.__fetchTreeData(this.props.id);

        const dimensions = this.treeContainer.getBoundingClientRect();
        this.setState({
            translate: {
                x: dimensions.width / 2,
                y: dimensions.height / 3
            }
        });
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        let data = {
            name: this.state.name,
            mno: this.state.mno,
            sponsor_id: this.props.username
        };
        //console.log(this.state.balance);

        if (this.state.balance >= 100) {


            PostData(`/${this.props.apikey}/${this.props.username}/adduser`, data)
                .then((resp) => {
                    confirmAlert({
                        title: 'Successful',
                        message: resp['message'],
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: this.__getData()
                            }
                        ]
                    })
                })
        }

        else {
            confirmAlert({
                title: 'Failed',
                message: 'You do not have sufficient balance in your wallet . Please add money to your wallet and then try.',
                buttons: [
                    {
                        label: 'OKAY'
                    }
                ]
            })
        }

    }

    componentDidMount() {

        this.__getData();

    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.__getData();
        }
    }

    render() {
        let { classes } = this.props;

        return (
            <div>
                < div id="treeWrapper" style={containerStyles} ref={tc => (this.treeContainer = tc)}>

                    {!this.state.isLoading ? <Tree
                        data={this.state.data}
                        orientation={"vertical"}
                        pathFunc={`elbow`}
                        separation={{ siblings: 2, nonSiblings: 2 }}
                        translate={this.state.translate}
                        onClick={this.onClick}

                    /> : null}

                </div >
                {!this.state.isLoading ? (
                    <div>
                        {this.state.node_count < 4 ? <form onSubmit={this.onSubmit}>
                            <Card className={classes.root}>
                                <CardHeader
                                    title={`Enter Details`}
                                />
                                <CardContent>


                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6} md={6}>
                                            <TextField
                                                label="Name"
                                                
                                                name="name"
                                                value={this.state.name}
                                                margin="normal"
                                                variant="outlined"
                                                labelwidth={this.state.labelWidth}
                                                required
                                                fullWidth
                                                onChange={this.onChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6} md={6}>
                                            <TextField
                                                label="Phone"
                                                
                                                value={this.state.mno}
                                                margin="normal"
                                                name="mno"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                onChange={this.onChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        type="submit"

                                    >
                                        Submit
                                        </Button>
                                </CardActions>


                            </Card>
                        </form> : <Warning />}
                    </div>
                ) : null}

            </div>
        );
    }
}

export default withStyles(styles)(TreeComponent);


export function Warning({ }) {
    return (
        <React.Fragment>
            <div
                style={{
                    color: red[500],
                    backgroundColor: red[50],
                    border: `solid thin ${red[200]}`,
                    textAlign: 'center',
                    padding: '10px',
                    margin: '10px'
                }}
            >You have already joined 4 members.</div>
        </React.Fragment>
    )
}