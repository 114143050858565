import React, { Component } from 'react'
import { Badge, Typography, Chip } from '@material-ui/core';
import { GetData } from '../../api/service';


export default class RepurchaseNotification extends Component {
    state = {
        status: false
    }

    // componentDidMount() {
    //     GetData(`/${this.props.apikey}/${this.props.username}/${this.props.username}/getrepurchasenotice`)
    //     .then((resp) => {
    //         //console.log(resp)
    //         this.setState({
    //             status: resp
    //         })
    //     })
    // }
    render() {
        return (
            <div>
                {this.state.status ? (<marquee >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Chip
                            label={
                                <Typography style={{color: 'white'}}>
                                    Please re purchase for the month {this.state.status[0].month} / {this.state.status[0].year}
                                </Typography>
                            }
                            color="secondary" /> &nbsp;

                    </div>

                </marquee>) : null}
            </div>
        )
    }
}
