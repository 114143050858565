import React, { Component } from 'react';
import Tree from 'react-d3-tree';
import { GetData } from './../../../api/service';

const myTreeData = [
    {
        name: 'Me',
        attributes: {
            ID: 'YDS000001',
            level: '3',
        },
        children: [
            {
                name: 'Pranjal Saikia',
                attributes: {
                    ID: 'YDS000002',
                    level: '2',
                },
                _collapsed: true,
                children: [
                    {
                        name: 'Chandan Nath',
                        attributes: {
                            ID: 'YDS000003',
                            level: '1',
                        },
                    },
                    {
                        name: 'Add New',
                    },
                ],
            },
            {
                name: 'Brikudor Seal',
                attributes: {
                    ID: 'YDS000004',
                    level: '1',
                },
            },
        ],
    },
];

const containerStyles = {
    width: '100%',
    height: '90vh',
}

class TreeComponent extends Component {
    state = {
        isLoading: true,
        data: []
    }

    __fetchTreeData = (user_id) => {
        GetData(`/${this.props.apikey}/${this.props.username}/${user_id}/gettree`)
            .then((resp) => {

                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp,
                        isLoading: false,

                    })
                } else {
                    this.setState({
                        isLoading: true
                    })
                }
            })
    }

    onClick = (e) => {
        
    }

    __getData = () => {
        this.__fetchTreeData(this.props.id);

        const dimensions = this.treeContainer.getBoundingClientRect();
        this.setState({
            translate: {
                x: dimensions.width / 2,
                y: dimensions.height / 3
            }
        });
    }

    componentDidMount() {

        this.__getData();
        
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.__getData();
        }
    }

    render() {
        return (
            < div id="treeWrapper" style={containerStyles} ref={tc => (this.treeContainer = tc)}>

                {!this.state.isLoading ? <Tree
                    data={this.state.data}
                    orientation={"vertical"}
                    pathFunc={`step`}
                    separation={{ siblings: 2, nonSiblings: 2 }}
                    translate={this.state.translate}
                    onClick={this.onClick}
                    
                /> : null}

            </div >
        );
    }
}

export default TreeComponent;