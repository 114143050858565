import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class StockTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }



    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Purpose",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Amount",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton onClick={this.props.onAddClick}>
                        <Add />
                    </IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (Array.isArray(table_data) && table_data.length > 0) {
            data = table_data.map((el, index) =>
                [index + 1, el.entry_date, el.narration,el.amount, el.status, <Launch onClick={this.onActionClick.bind(this, el)} />]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Previous Requests"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
