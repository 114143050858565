import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import { GetData } from '../../api/service';
import IncomeTable from './IncomeTable';

const styles = theme => ({

})

class IncomeRepurchaseLanding extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        GetData(`/${this.props.apikey}/${this.props.username}/${this.props.username}/getincome-repurchase`)
        .then((resp) => {
            //console.log(resp)
            this.setState({
                data: resp,
                isLoaded: true
            })
        })
    }
    
    render() {
        let { classes } = this.props;
        return (
            <div style={{padding: '10px'}}>

                {this.state.isLoaded ? 
                    <IncomeTable
                        table_data={this.state.data}
                    />
                    : null            
            }
                
            </div>
        )
    }
}

export default withStyles(styles)(IncomeRepurchaseLanding)