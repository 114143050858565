import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid, OutlinedInput } from '@material-ui/core';
import { GetData } from '../../../api/service';
import FileUploadNew from './FileUploadNew';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        width: '100%',
        margin: theme.spacing.unit
    },
    bottomButton: {
        padding: theme.spacing.unit
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            PAN: '',
            PAN_photo:[],
            address: '',
            city: '',
            state: '',
            pin: '',
            dob: '',
            mno: '',
            email: '',
            nominee_name: '',
            nominee_relation: '',
            account_holder_name: '',
            bank: '',
            branch: '',
            account_no: '',
            ifsc_code: '',
            name: '',
            gender: '',
            m_status: '',
            father_name: '',
            nominee_dob: '',
            errors: []
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getInitialState() {
        this.setState({
            id: '',
            PAN: '',
            PAN_photo:[],
            address: '',
            city: '',
            state: '',
            pin: '',
            dob: '',
            mno: '',
            email: '',
            nominee_name: '',
            nominee_relation: '',
            account_holder_name: '',
            bank: '',
            branch: '',
            account_no: '',
            ifsc_code: '',
            name: '',
            gender: '',
            m_status: '',
            father_name: '',
            nominee_dob: '',
            errors: []
        })
    }

    onSubmitFile = (data) => {
        this.setState({
            PAN_photo: data
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.__getInitialState();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.__getInitialState();
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        let data = this.state;


        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);

        }

        this.__getInitialState();

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;

        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <TextField
                                label="Name"
                                fullWidth
                                className={classes.textField}
                                name="name"
                                value={this.state.name}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <TextField
                                label="Phone"
                                fullWidth
                                className={classes.textField}
                                name="mno"
                                value={this.state.mno}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <TextField
                                label="Address"
                                fullWidth
                                className={classes.textField}
                                name="address"
                                value={this.state.address}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <TextField
                                label="City"
                                fullWidth
                                className={classes.textField}
                                name="city"
                                value={this.state.city}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <TextField
                                label="State"
                                fullWidth
                                className={classes.textField}
                                name="state"
                                value={this.state.state}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <TextField
                                label="Pin"
                                fullWidth
                                className={classes.textField}
                                name="pin"
                                value={this.state.pin}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />


                            <TextField
                                type="date"
                                label="Date of Birth"
                                fullWidth
                                className={classes.textField}
                                name="dob"
                                value={this.state.dob}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    },
                                    shrink: true
                                }}
                                required
                            />


                            <FormControl required variant="outlined" className={classes.formControl}>
                                <InputLabel

                                >
                                    Gender
                                </InputLabel>
                                <Select
                                    name="gender"
                                    value={this.state.gender}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="gender"

                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={`MALE`}>MALE</MenuItem>
                                    <MenuItem value={`FEMALE`}>FEMALE</MenuItem>
                                    <MenuItem value={`OTHERS`}>OTHERS</MenuItem>
                                </Select>
                            </FormControl>


                            <FormControl required variant="outlined" className={classes.formControl}>
                                <InputLabel

                                >
                                    Maratial Status
                                </InputLabel>
                                <Select
                                    name="m_status"
                                    value={this.state.m_status}
                                    onChange={this.onChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={200}
                                            name="m_status"

                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={`MARRIED`}>MARRIED</MenuItem>
                                    <MenuItem value={`UNMARRIED`}>UNMARRIED</MenuItem>
                                </Select>
                            </FormControl>


                            <TextField
                                label="Father's Name"
                                fullWidth
                                className={classes.textField}
                                name="father_name"
                                value={this.state.father_name}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />

                            <TextField
                                label="Nominee Name"
                                fullWidth
                                className={classes.textField}
                                name="nominee_name"
                                value={this.state.nominee_name}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />


                            <TextField
                                label="Nominee Relation"
                                fullWidth
                                className={classes.textField}
                                name="nominee_relation"
                                value={this.state.nominee_relation}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                required
                            />


                            <TextField
                                type="date"
                                label="Nominee Date of Birth"
                                fullWidth
                                className={classes.textField}
                                name="nominee_dob"
                                value={this.state.nominee_dob}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    },
                                    shrink: true
                                }}
                                required
                            />


                            <TextField
                                label="PAN Card"
                                fullWidth
                                className={classes.textField}
                                name="PAN"
                                value={this.state.PAN}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                
                            />


                            <TextField
                                label="Acount Holder"
                                fullWidth
                                className={classes.textField}
                                name="account_holder_name"
                                value={this.state.account_holder_name}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                
                            />

                            <TextField
                                label="Bank"
                                fullWidth
                                className={classes.textField}
                                name="bank"
                                value={this.state.bank}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                
                            />


                            <TextField
                                label="Branch"
                                fullWidth
                                className={classes.textField}
                                name="branch"
                                value={this.state.branch}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                
                            />


                            <TextField
                                label="Account No"
                                fullWidth
                                className={classes.textField}
                                name="account_no"
                                value={this.state.account_no}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                
                            />

                            <TextField
                                label="IFSC Code"
                                fullWidth
                                className={classes.textField}
                                name="ifsc_code"
                                value={this.state.ifsc_code}
                                onChange={this.onChange}
                                margin="normal"
                                variant="outlined"
                                
                            />

                        
                      
                            <Typography
                                variant="headline"
                                gutterBottom
                            >
                                PAN Card Upload
                            </Typography>
                            <FileUploadNew
                                type="PAN"
                                username={this.props.username}
                                apikey={this.props.apikey}
                                onSubmit={this.onSubmitFile.bind(this)} />
                        



                            <div className={classes.bottomButton}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)