import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';
import { GetData,PostData } from '../../api/service';
import  {toast} from 'react-toastify'


export default class StockTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false,
            show:false
           
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    onActiveClick(el) {
       
        if(el.status == 1){
            toast.info('You have already sent the money.');
            this.setState({
                show: false,
                status:true
            })
        }
        else{

        this.setState({
            show: true,
            send_data: el
        })
    }
    }

    handleClose = () => {
        this.setState({
            show: false,
            send_data: ''
        }, () => {
            this.props.refreshData();
        })
    }

    getStatus = (value) => {
        if (value === 1) {
            return 'Received';
        } else if (value === 0) {
            return 'Pending';
        }

        else if (value === 2) {
            return 'Rejected';
        }
    }



    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Sponsor Id",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Request Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Send Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Purpose",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Amount",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton onClick={this.props.onAddClick}>
                        <Add />
                    </IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (Array.isArray(table_data) && table_data.length > 0) {
            data = table_data.map((el, index) =>
                [index + 1,el.sponsor_id, el.request_date,el.send_date, el.narration,el.amount, this.getStatus(el.status), <Launch onClick={this.onActionClick.bind(this, el)} />]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Send Requests"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}

