import React, { Component } from 'react'
import CourseTable from './RoutineTable';
import CourseAdd from './RoutineAdd';
import { LinearProgress } from '@material-ui/core';
import Snack from '../Snackbar/Snack';
import { PostData, GetData, PutData, DeleteData } from '../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SmallBread from '../SmallBread';
import { toast } from 'react-toastify';



export default class RequestMoney extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoading: true,
            status: false,
            message: '',
            table_data: [],
            edit: false,
            edit_data: [],
            products: [],
            is_all_loaded: false
        }

    }

    componentDidMount(){
        this.__getData();
    }

    refreshData = () => {
        this.__getData();
    }

    __getData() {

        GetData(`/${this.props.apikey}/${this.props.username}/getwalletrequestsown`).then((resp) => {
            //console.log(resp)
            this.setState({
                table_data: resp,
                isLoading: false
            })
        })

    }


    onAddClick() {
        this.setState({
            show: !this.state.show,
            edit: false
        })
    }

    setData(data) {
        //send the data to the server

        PostData(`/${this.props.apikey}/requestmoney`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp,
                    edit: false
                })
                this.__getData();
            })
    }

    actionClick(el) {
        if(el.status != 1){
        this.setState({
            edit: true,
            edit_data: el,
            show: true
        })
    }
    else{
        toast.info('You have already received the money. Can not change now');
    }
    }

    esetData(data) {
        //send the data to the server

        PutData(`/${this.props.apikey}/${data.id}/updaterequest`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }

    onDeleteClick(id) {
        this.setState({
            show: false
        })
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm(id) {
        DeleteData(`/${this.props.apikey}/${id}/deleterequest`)
            .then((resp) => {
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }


    render() {
        return (
            <div>

                <SmallBread
                    data={
                        [
                            {
                                title: 'Wallet',
                                link: '/wallet'
                            },
                            {
                                title: 'View',
                                link: ''
                            }
                        ]
                    }
                />


                {this.state.status ? <Snack
                    open={this.state.status}
                    message={this.state.message}
                    handleClose={() =>
                        this.setState({
                            status: false,
                            message: ''
                        })
                    } /> : null}

                <div style={{ padding: '20px' }}>

                    {this.state.isLoading ? <LinearProgress color="secondary" /> : null}


                    <CourseAdd
                    {...this.props}
                        show={this.state.show}
                        handleClose={this.onAddClick.bind(this)}
                        setData={this.setData.bind(this)}
                        edit={this.state.edit}
                        edit_data={this.state.edit_data}
                        esetData={this.esetData.bind(this)}
                        onDeleteClick={this.onDeleteClick.bind(this)}
                        is_all_loaded={this.state.is_all_loaded}
                    />



                    {!this.state.isLoading ? <CourseTable
                        table_data={this.state.table_data}
                        isLoading={this.state.isLoading}
                        onAddClick={this.onAddClick.bind(this)}
                        actionClick={this.actionClick.bind(this)} 
                        refreshData={this.refreshData}
                        /> : null}
                </div>
            </div>
        )
    }
}
