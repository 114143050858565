import React from 'react'
import { GetData } from '../../api/service';
import {Card, Tag} from 'antd'
import {Typography} from '@material-ui/core'

export default function BurnoutPage(props) {

    const [data, setData] = React.useState([])
    const [isLoaded, setIsLoaded] = React.useState(false)

    function getData(){
        GetData(
            `/${props.apikey}/${props.username}/${props.username}/getuserincomenew`
          ).then((resp) => {
            setData([...resp.burn_out_pairs])
            setIsLoaded(true)
          });
    }

    React.useEffect(() => {
        getData()
    }, [])
  return (
    <div>
        {isLoaded && <Card style={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom style={{fontWeight: 700}}>BURNOUT PAIRS</Typography>


                    {Array.isArray(data) && data.length > 0 && data.map((el,index) => <Tag color="#D35520" key={index}>{el}</Tag>)}

                  </Card>}
    </div>
  )
}
