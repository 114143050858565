import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import SmallBread from '../../SmallBread';
import TreeComponent from './TreeComponent';

const styles = theme => ({

})

class TreeView extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div>
        <SmallBread
          data={
            [
              {
                title: 'Registration',
                link: '/registration'
              },
              {
                title: 'New',
                link: ''
              }
            ]
          }
        />


        <TreeComponent
        
          username={this.props.username}
          id={this.props.username}
          apikey={this.props.apikey}
          name={this.props.name}
        />


      </div>
    )
  }
}

export default withStyles(styles)(TreeView)