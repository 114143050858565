import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Card, CardHeader, CardContent, TextField, CardActionArea, Button, Typography, FormControl, Fade } from '@material-ui/core';
import Send from '@material-ui/icons/Send'
import { green, red } from '@material-ui/core/colors';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',

    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    },
    forgotCard: {
        width: 400,
        padding: '20px',
        boxShadow: 'none'
    },
    loginText: {
        fontSize: 25,
        fontWeight: 200
    },
    inputText: {
        padding: '15px',
        paddingLeft: '25px',
        border: 'solid thin #F9F9F9',
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',
        backgroundColor: '#F9F9F9',
        marginBottom: '20px',
        outline: 'none'
    },
    colorMessage: {
        color: red[300]
    }
})

class FormTwo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            otp: ''
        }

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.props.setOtp(this.state.otp);
    }

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.container}>
                <Fade in={true} timeout={{ enter: 1500 }}>
                <form onSubmit={this.onSubmit}>
                    <Card className={classes.forgotCard}>
                        
                        <CardContent>

                            <div align="center">
                                <Typography
                                    className={classes.loginText}
                                    gutterBottom
                                >
                                    Forgot Password ?
                                </Typography>
                            </div>

                            <Typography 
                                variant="body1"
                                className={classes.colorMessage}
                                >
                                {this.props.message}
                            </Typography>

                            <FormControl fullWidth required>

                                <input
                                    required
                                    className={classes.inputText}
                                    name="otp"
                                    type="number"
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    placeholder="Please enter the OTP"
                                />

                            </FormControl>

                            <div align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    className={classes.button}
                                >
                                    Next
                                        </Button>
                            </div>

                            <br />

                            <div align="center">
                                <Typography
                                    variant="caption"
                                >
                                    Go to home ? <b><Link to="/login">Click Here</Link></b>
                                </Typography>

                            </div>
                            

                        </CardContent>

                        
                    </Card>
                </form>
                </Fade>
            </div>
        )
    }
}


export default withStyles(styles)(FormTwo)