import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import AuthService from "./../auth_components/js/AuthService";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Avatar,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Persons from "@material-ui/icons/Group";
import Search from "@material-ui/icons/Search";
import Build from "@material-ui/icons/Build";
import Money from "@material-ui/icons/Money";
import { blueGrey } from "@material-ui/core/colors";
import AccountBalanceWalletRoundedIcon from "@material-ui/icons/AccountBalanceWalletRounded";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TableChartIcon from "@material-ui/icons/TableChart";

const drawerWidth = 230;

const styles = (theme) => ({
  root: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#2196F3",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    height: 140,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing.unit,
    textAlign: "center",
    alginItems: "center",
    justifyContent: "center",
    backgroundColor: blueGrey[900],
    color: "white",
    paddingTop: 30,
    marginBottom: 30,
  },
  navIconHide: {
    display: "block",
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    left: 0,
    backgroundColor: blueGrey[900],
    color: "white",
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
    },
  },
  listSmall: {
    paddingLeft: "35px",
  },
  listDiv: {
    color: blueGrey[400],
    fontSize: 16,
    "&:hover": {
      color: "white",
    },
  },
  linkButton: {
    "&:hover": {
      color: "white",
    },
  },
  listFont: {
    fontSize: 12,
    color: "white"
  },
});

class DrawerMain extends React.Component {
  constructor(props) {
    super(props);
    let user = JSON.parse(localStorage.getItem("user"));
    let name = user.user.name;
    this.state = {
      name: name,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      open: [false, false, false, false],
    };
  }

  handleClick = (e) => {
    let open = this.state.open;
    open[e] = !this.state.open[e];
    this.setState({
      open,
    });
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;

    const menuListObject = [
      {
        title: "Overview",
        link: "/",
        submenu: [],
        icon: <Dashboard className={classes.listDiv} />,
      },
      {
        title: "My Profile",
        link: "/profile",
        submenu: [],
        icon: <Person className={classes.listDiv} />,
      },
      // {
      //     title: "New User Registration",
      //     link: "/registration",
      //     submenu: [],
      //     icon: <PersonAddIcon className={classes.listDiv} />
      // },
      {
        title: "Network",
        link: "/tree",
        submenu: [],
        icon: <Persons className={classes.listDiv} />,
      },
      //  {
      //     title: "Send Money",
      //     link: "/sendmoney",
      //     submenu: [],
      //     icon:<ArrowBackIcon className={classes.listDiv} />
      // },
      // {
      //     title: "Request Money",
      //     link: "/requestmoney",
      //     submenu: [],
      //     icon:<ArrowForwardIcon className={classes.listDiv} />
      // },
      {
        title: "Income",
        link: "/income",
        submenu: [],
        icon: <Money className={classes.listDiv} />,
      },
      {
        title: "Repurchase Income",
        link: "/income-repurchase",
        submenu: [],
        icon: <Money className={classes.listDiv} />,
      },
      {
        title: "Burnout Pairs",
        link: "/burnout-pairs",
        submenu: [],
        icon: <Money className={classes.listDiv} />,
      },
      
      // {
      //     title: "Users (Tabular View)",
      //     link: "/users",
      //     submenu: [],
      //     icon: <TableChartIcon className={classes.listDiv} />
      // },
    ];

    let menu = [];

    menu = menuListObject.map((el, index) => {
      let i = [];
      let j = [];
      if (el.submenu.length > 0) {
        if (this.state.open[index]) {
          i = <ExpandLess className={classes.listDiv} />;
          j = (
            <Collapse in={this.state.open[index]} timeout="auto" unmountOnExit>
              <List>
                {el.submenu.map((el, index) => (
                  <Link to={el.link} key={index}>
                    <ListItem button className={classes.listSmall}>
                      <ListItemText>
                        <Typography
                          className={classes.listFont}
                          variant="title1"
                          color="inherit"
                        >
                          {el.title}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>
          );
        } else {
          i = <ExpandMore className={classes.listDiv} />;
          j = [];
        }
      }
      return (
        <List disablePadding key={index} className={classes.listDiv}>
          {el.link !== "" ? (
            <Link to={el.link}>
              <ListItem
                button
                onClick={this.handleClick.bind(this, index)}
                className={classes.linkButton}
              >
                <ListItemIcon>{el.icon}</ListItemIcon>
                <ListItemText>
                  <Typography className={classes.listFont} color="inherit">
                    {el.title}
                  </Typography>
                </ListItemText>

                {el.submenu.length > 0 ? i : null}
              </ListItem>
            </Link>
          ) : (
            <ListItem button onClick={this.handleClick.bind(this, index)}>
              <ListItemText>
                <Typography variant="subtitle2" color="inherit">
                  {el.title}
                </Typography>
              </ListItemText>
              {el.submenu.length > 0 ? i : null}
            </ListItem>
          )}
          {j}
        </List>
      );
    });

    return (
      <div>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div className={classes.toolbar}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alginItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alginItems: "center",
                  flexDirection: "row",
                }}
              >
                <Avatar
                  src={`/images/icons/user.png`}
                  
                  style={{
                    width: 80,
                    height: 80,
                    backgroundColor: "white",
                    border: "solid 2px white",
                  }}
                />
              </div>
              <div>
                <Typography variant="h6" color="inherit">
                  {this.state.name}
                </Typography>
                <Typography variant="body2" style={{marginTop: '-5px'}} color="inherit">
                  Distributors' Panel
                </Typography>
              </div>
            </div>
          </div>

          {menu}
        </Drawer>
      </div>
    );
  }
}

DrawerMain.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrawerMain);
