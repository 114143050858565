import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardHeader,
  Typography,
  Avatar,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Divider,
  CardActions,
  Button,
  LinearProgress,
  Container,
} from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import { blue } from "@material-ui/core/colors";
import { GetData, PutData } from "../../api/service";
import Sel from "react-select";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SmallBread from "../SmallBread";
import FileUploadNew from "./FileUploadNew";
import { Alert, Result } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  titleColor: {
    color: "#2196F3",
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  mulsel: {
    zIndex: 5,
  },

  mulsel1: {
    zIndex: 6,
  },
  root: {
    padding: "10px",
    backgroundColor: "none",
  },
});

class ProfileLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      PAN: "",
      address: "",
      city: "",
      state: "",
      pin: "",
      dob: "",
      mno: "",
      email: "",
      nominee_name: "",
      nominee_relation: "",
      account_holder_name: "",
      bank: "",
      branch: "",
      account_no: "",
      ifsc_code: "",
      name: "",
      gender: "",
      m_status: "",
      father_name: "",
      nominee_dob: "",
      PAN_photo: "",
      errors: [],
      isLoaded: false,
      is_kyc_done: "",
    };
  }

  __getInitialState() {
    this.setState({
      id: "",
      PAN: "",
      address: "",
      city: "",
      state: "",
      pin: "",
      dob: "",
      mno: "",
      email: "",
      nominee_name: "",
      nominee_relation: "",
      account_holder_name: "",
      bank: "",
      branch: "",
      account_no: "",
      ifsc_code: "",
      name: "",
      gender: "",
      m_status: "",
      father_name: "",
      nominee_dob: "",
      PAN_photo: "",
      errors: [],
    });
  }

  handleSChange = (name, sub_code) => {
    this.setState({
      [name]: sub_code,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    GetData(
      `/${this.props.apikey}/${this.props.username}/${this.props.id}/getusersingle`
    ).then((resp) => {
      //console.log(resp);
      if (Array.isArray(resp) && resp.length > 0) {
        //let is_pan_verified = resp[0].is_PAN_verified;
        let isLoaded = true;
        // if (is_pan_verified === 0){
        //     isLoaded = true;
        // }else{
        //     isLoaded = false;
        // }
        this.setState({
          ...resp[0],
          isLoaded,
        });
      }
    });

    GetData(
      `/${this.props.apikey}/${this.props.username}/check-if-kyc-done`
    ).then((resp) => {
      //console.log(resp);
      this.setState({
        is_kyc_done: resp,
      });
    });
  }

  onSubmitFile = (data) => {
    if(Array.isArray(data) && data.length > 0){
      this.setState({
        PAN_photo: data[0].file_id,
      });
    }
   
  };

  onSubmit = (e) => {
    e.preventDefault();

    let data = this.state;

    PutData(`/${this.props.apikey}/${data.id}/updateuser`, data).then(
      (resp) => {
        confirmAlert({
          title: "Successful",
          message: resp,
          buttons: [
            {
              label: "OKAY",
            },
          ],
        });
      }
    );
  };

  render() {
    let { classes } = this.props;

    return (
      <div>
        <SmallBread
          data={[
            {
              title: "Profile",
              link: "/profile",
            },
            {
              title: "View",
              link: "",
            },
          ]}
        />

        <Container>
          {this.state.is_kyc_done == 0 && (
            <Alert
              message="KYC Not Verified"
              showIcon
              description={`You have not verified to your KYC, you can click on the button to complete your verification`}
              type="error"
            />
          )}

          {this.state.is_kyc_done == 1 && (
            <Result
              status="success"
              title="KYC Verfication Successful "
              subTitle="Your KYC verification has been done by the administrator."
            />
          )}

          <form onSubmit={this.onSubmit}>
            <div className={classes.root}>
              <CardHeader
                title={<Typography variant="h6">My Profile</Typography>}
                subheader={`Update your profile`}
                avatar={
                  <Avatar style={{ backgroundColor: blue[400] }}>
                    <Person />
                  </Avatar>
                }
              />

              <CardContent>
                <Typography variant="headline" gutterBottom>
                  Primary Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Name"
                      className={classes.textField}
                      name="name"
                      value={this.state.name}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      required
                      disabled
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Email"
                      className={classes.textField}
                      value={this.state.email}
                      margin="normal"
                      name="email"
                      variant="outlined"
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Phone"
                      className={classes.textField}
                      value={this.state.mno}
                      margin="normal"
                      name="mno"
                      variant="outlined"
                      required
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Date of Birth"
                      className={classes.textField}
                      value={this.state.dob}
                      margin="normal"
                      name="dob"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Father Name"
                      className={classes.textField}
                      value={this.state.father_name}
                      margin="normal"
                      name="father_name"
                      variant="outlined"
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel
                        ref={(ref) => {
                          this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-age-simple"
                      >
                        Gender
                      </InputLabel>
                      <Select
                        value={this.state.gender}
                        name="gender"
                        onChange={this.onChange}
                        input={<OutlinedInput labelWidth={200} name="gender" />}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="MALE">MALE</MenuItem>
                        <MenuItem value="FEMALE">FEMALE</MenuItem>
                        <MenuItem value="OTHERS">OTHERS</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel
                        ref={(ref) => {
                          this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-age-simple"
                      >
                        Maratial status
                      </InputLabel>
                      <Select
                        value={this.state.m_status}
                        name="m_status"
                        onChange={this.onChange}
                        input={
                          <OutlinedInput labelWidth={200} name="m_status" />
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="MARRIED">MARRIED</MenuItem>
                        <MenuItem value="UNMARRIED">UNMARRIED</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Address"
                      className={classes.textField}
                      value={this.state.address}
                      margin="normal"
                      name="address"
                      multiline
                      rows="3"
                      variant="outlined"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="City"
                      className={classes.textField}
                      value={this.state.city}
                      margin="normal"
                      name="city"
                      variant="outlined"
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="State"
                      className={classes.textField}
                      value={this.state.state}
                      margin="normal"
                      name="state"
                      variant="outlined"
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Pin"
                      className={classes.textField}
                      value={this.state.pin}
                      margin="normal"
                      name="pin"
                      variant="outlined"
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>

                <br />
                <Typography variant="headline" gutterBottom>
                  Nominee Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Nominee Name"
                      className={classes.textField}
                      name="nominee_name"
                      value={this.state.nominee_name}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Nominee Relation"
                      className={classes.textField}
                      name="nominee_relation"
                      value={this.state.nominee_relation}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      label="Nominee Date of Birth"
                      className={classes.textField}
                      name="nominee_dob"
                      value={this.state.nominee_dob}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>

                <br />
                <Typography variant="headline" gutterBottom>
                  Bank Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="PAN"
                      className={classes.textField}
                      name="PAN"
                      value={this.state.PAN}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      disabled={Boolean(this.state.is_PAN_verified)}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Account Holder"
                      className={classes.textField}
                      name="account_holder_name"
                      value={this.state.account_holder_name}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Bank"
                      className={classes.textField}
                      name="bank"
                      value={this.state.bank}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Branch"
                      className={classes.textField}
                      name="branch"
                      value={this.state.branch}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="Account No"
                      className={classes.textField}
                      name="account_no"
                      value={this.state.account_no}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      onChange={this.onChange}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      label="IFSC Code"
                      className={classes.textField}
                      name="ifsc_code"
                      value={this.state.ifsc_code}
                      margin="normal"
                      variant="outlined"
                      labelwidth={this.state.labelWidth}
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>

                <br />

                {this.state.isLoaded ? (
                  <Grid container spacing={24}>
                    <Grid item xs={12} lg={12} sm={12} md={12}>
                      <Typography variant="headline" gutterBottom>
                        PAN Card Upload
                      </Typography>
                      <FileUploadNew
                        type="PAN"
                        username={this.props.username}
                        apikey={this.props.apikey}
                        onSubmit={this.onSubmitFile.bind(this)}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </CardContent>

              <CardActions style={{ justifyContent: "flex-end" }}>
                <Button variant="outlined" color="secondary" type="submit">
                  Update
                </Button>
              </CardActions>
            </div>
          </form>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(ProfileLanding);
