import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';
import SettingsContainer from './Containers/SettingsContainer';
import ForgotPasswordContainer from './Containers/ForgotPasswordContainer';

import ProfileContainer from './Containers/ProfileContainer';

import TreeContainer from './Containers/TreeContainer';
import IncomeContainer from './Containers/IncomeContainer';

import RegistrationContainer from './Containers/RegistrationContainer';
import SendMoneyContainer from './Containers/SendMoneyContainer';
import RequestMoneyContainer from './Containers/RequestMoneyContainer';
import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import UsersListContainer from './Containers/UsersListContainer';
import RegistrationContainerNaked from './Containers/RegistrationContainerNaked';
import IncomeRepurchaseContainer from './Containers/IncomeRepurchaseContainer';
import BurnoutContainer from './Containers/BurnoutContainer';


  toast.configure();


class App extends Component {
  render() {
    return (
      <Router history={history} >
        <div>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/registration-new" component={RegistrationContainerNaked} />
            <Route path="/forgotpassword" component={ForgotPasswordContainer} />
            <Route exact path="/" render={(props) => <HomePage {...props} />} />
            <Route exact path="/tree" render={(props) => <TreeContainer {...props} />} />
            <Route exact path="/registration" render={(props) => <RegistrationContainer {...props} />} />
            <Route exact path="/settings" component={SettingsContainer} />
            <Route exact path="/profile" component={ProfileContainer} />
            <Route exact path="/income" component={IncomeContainer} />
            <Route exact path="/income-repurchase" component={IncomeRepurchaseContainer} />
            <Route exact path="/burnout-pairs" component={BurnoutContainer} />
            <Route exact path="/sendmoney" component={SendMoneyContainer} />
            <Route exact path="/requestmoney" component={RequestMoneyContainer} />
            <Route exact path="/users" component={UsersListContainer} />
            
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
