import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import { PostData, GetData } from '../../../api/service';



export default class StockTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false,
            show: false,
            send_data: ''
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onPrintClick(id) {
        this.props.onPrintClick(id)
    }

    onClick() {
        console.log("hey");
    }

    getType(value) {
        if (value === 0) {
            return 'FRESH';
        } else {
            return 'RE-PURCHASE';
        }
    }

    getStatus = (value) => {
        if (value === 1) {
            return 'Active';
        } else if (value === 0) {
            return 'Inactive';
        }
    }


    handleClose = () => {
        this.setState({
            show: false,
            send_data: ''
        }, () => {
            this.props.refreshData();
        })
    }



    render() {
        const columns = [
            {
                name: "User ID",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Phone",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Email",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Current Level",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "PAN",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Sponsor ID",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.user_id,
                    el.name,
                    el.mno,
                    el.email,
                    el.level,
                    el.PAN,
                    el.sponsor_id,
                    this.getStatus(el.main_status),
                   
                    <Launch onClick={this.onActionClick.bind(this, el)} />
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Users"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}

