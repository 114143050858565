import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import IncomeLanding from '../Components/Income/IncomeLanding';

class IncomeContainer extends Component {
    constructor(props) {
        super(props);

        let us = JSON.parse(localStorage.getItem('user'));
        let username = us.user.email;
        let id = us.user.id;
        let name = us.user.name;

        this.state = {
            username: username,
            apikey: us.user.api_key,
            id: id,
            name
        }

    }
    render() {
        return (
            <div>
                <Navbar 
                history={this.props.history} 
                username={this.state.username} 
                name={this.state.name}
                apikey={this.state.apikey}
                 />
                <div className="main-body">

                    <IncomeLanding
                        id={this.state.username}
                        history={this.props.history}
                        username={this.state.username}
                        apikey={this.state.apikey} />

                </div>
            </div>
        )
    }
}

export default withAuth(IncomeContainer);