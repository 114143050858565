import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Alert, Button, Input, Select, Result, Modal, Descriptions, message } from "antd";
import { SearchOutlined } from "@material-ui/icons";
import { PostData } from "../api/service";

const { Option } = Select;

export default function RegistrationContainerNaked() {


  const [sponsor_id, setSponsorId] = React.useState("");
  const [state, setState] = React.useState({
    name: "",
    PAN: "",
    address: "",
    city: "",
    state: "",
    pin: "",
    dob: "",
    m_status: "",
    father_name: "",
    mno: "",
    email: "",
    nominee_name: "",
    nominee_relation: "",
    nominee_dob: "",
    account_holder_name: "",
    bank: "",
    branch: "",
    account_no: "",
    ifsc_code: "",
    PAN_photo: "",
    position: "",
  });

  const [error, setError] = React.useState({
    sponsor_id: "",
    name: "",
    PAN: "",
    address: "",
    city: "",
    state: "",
    pin: "",
    dob: "",
    m_status: "",
    father_name: "",
    mno: "",
    email: "",
    nominee_name: "",
    nominee_relation: "",
    nominee_dob: "",
    account_holder_name: "",
    bank: "",
    branch: "",
    account_no: "",
    ifsc_code: "",
    position: "",
  });

  function onChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function onClear() {
    setState({
      name: "",
      PAN: "",
      address: "",
      city: "",
      state: "",
      pin: "",
      dob: "",
      m_status: "",
      father_name: "",
      mno: "",
      email: "",
      nominee_name: "",
      nominee_relation: "",
      nominee_dob: "",
      account_holder_name: "",
      bank: "",
      branch: "",
      account_no: "",
      ifsc_code: "",
      PAN_photo: "",
      position: "",
    });
  }

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function onSubmit() {
    if (handleValidation()) {
      // submit the data and validate the details
      setIsSubmitting(true);
      PostData(`/new-registration`, {
        sponsor_id,
        ...state,
      }).then((resp) => {
        if (resp.status == true) {

          Modal.success({
            title: "",
            content:
              <>
              <Result
                 status="success"
                 title="Successfully Registered"
                 subTitle="You can login to the portal after your ID has been activated. Kindly note down the following as you will need then to login into the system."
      
              >
                <Descriptions column={1} size="small" bordered={true}>
                  <Descriptions.Item label="Registration ID">{resp?.data?.user_id}</Descriptions.Item>
                  <Descriptions.Item label="Password">{resp?.data?.password}</Descriptions.Item>
                </Descriptions>
              </Result>
              </>,
            onOk() {},
            onCancel() {},
          });


        }else{
          Modal.error({
            title: resp.status_text,
            content: "Kindly Contact the administrator for help",
            onOk() {},
            onCancel() {},
          });
        }
      }).catch((err) => {
        message.error('Server Error')
      })
    }
  }

 

  function handleValidation() {
    let isValid = true;
    let err = [];

    if (state.name == "" || state.name == null) {
      isValid = false;
      err["name"] = "Mandatory Field";
    } else {
      err["name"] = "";
    }

    if (state.PAN == "" || state.PAN == null) {
      isValid = false;
      err["PAN"] = "Mandatory Field";
    } else {
      err["PAN"] = "";
    }

    // if (state.address == "" || state.address == null) {
    //   isValid = false;
    //   err["address"] = "Mandatory Field";
    // } else {
    //   err["address"] = "";
    // }

    // if (state.city == "" || state.city == null) {
    //   isValid = false;
    //   err["city"] = "Mandatory Field";
    // } else {
    //   err["city"] = "";
    // }

    // if (state.state == "" || state.state == null) {
    //   isValid = false;
    //   err["state"] = "Mandatory Field";
    // } else {
    //   err["state"] = "";
    // }

    // if (state.pin == "" || state.pin == null) {
    //   isValid = false;
    //   err["pin"] = "Mandatory Field";
    // } else {
    //   err["pin"] = "";
    // }

    // if (state.dob == "" || state.dob == null) {
    //   isValid = false;
    //   err["dob"] = "Mandatory Field";
    // } else {
    //   err["dob"] = "";
    // }

    // if (state.m_status == "" || state.m_status == null) {
    //   isValid = false;
    //   err["m_status"] = "Mandatory Field";
    // } else {
    //   err["m_status"] = "";
    // }

    // if (state.father_name == "" || state.father_name == null) {
    //   isValid = false;
    //   err["father_name"] = "Mandatory Field";
    // } else {
    //   err["father_name"] = "";
    // }

    if (state.mno == "" || state.mno == null) {
      isValid = false;
      err["mno"] = "Mandatory Field";
    } else {
      err["mno"] = "";
    }

    // if (state.email == "" || state.email == null) {
    //   isValid = false;
    //   err["email"] = "Mandatory Field";
    // } else {
    //   err["email"] = "";
    // }

    // if (state.nominee_name == "" || state.nominee_name == null) {
    //   isValid = false;
    //   err["nominee_name"] = "Mandatory Field";
    // } else {
    //   err["nominee_name"] = "";
    // }

    // if (state.nominee_relation == "" || state.nominee_relation == null) {
    //   isValid = false;
    //   err["nominee_relation"] = "Mandatory Field";
    // } else {
    //   err["nominee_relation"] = "";
    // }

    // if (state.nominee_dob == "" || state.nominee_dob == null) {
    //   isValid = false;
    //   err["nominee_dob"] = "Mandatory Field";
    // } else {
    //   err["nominee_dob"] = "";
    // }

    if (state.account_holder_name == "" || state.account_holder_name == null) {
      isValid = false;
      err["account_holder_name"] = "Mandatory Field";
    } else {
      err["account_holder_name"] = "";
    }

    if (state.bank == "" || state.bank == null) {
      isValid = false;
      err["bank"] = "Mandatory Field";
    } else {
      err["bank"] = "";
    }

    if (state.branch == "" || state.branch == null) {
      isValid = false;
      err["branch"] = "Mandatory Field";
    } else {
      err["branch"] = "";
    }

    if (state.account_no == "" || state.account_no == null) {
      isValid = false;
      err["account_no"] = "Mandatory Field";
    } else {
      err["account_no"] = "";
    }

    if (state.ifsc_code == "" || state.ifsc_code == null) {
      isValid = false;
      err["ifsc_code"] = "Mandatory Field";
    } else {
      err["ifsc_code"] = "";
    }

    // if (state.PAN_photo == "" || state.PAN_photo == null) {
    //   isValid = false;
    //   err["PAN_photo"] = "Mandatory Field";
    // } else {
    //   err["PAN_photo"] = "";
    // }

    if (state.position == "" || state.position == null) {
      isValid = false;
      err["position"] = "Mandatory Field";
    } else {
      err["position"] = "";
    }

    setError(err);

    return isValid;
  }

  const [sponsorIdSubmitting, setSponsorIdSubmitting] = React.useState(false);

  const [sponsorFound, setSponsorFound] = React.useState(false);
  const [sponsorMessage, setSponsorMessage] = React.useState(null);

  function searchSponsorId() {
    if (sponsor_id != "") {
      setSponsorIdSubmitting(true);
      PostData(`/get-sponsor-user-for-registration`, {
        sponsor_id,
      })
        .then((resp) => {
          
          setSponsorIdSubmitting(false);
          if (resp.status == true) {
            setSponsorFound(true);
            setSponsorMessage(resp);
          } else {
            setSponsorFound(false);
            setSponsorMessage(resp);
          }
        })
        .catch((err) => {
          setSponsorIdSubmitting(false);
        });
    }
  }

  function clearSponsorId() {
    setSponsorId("");
    setSponsorFound(false);
    setSponsorMessage(null);
  }

  return (
    <div style={{
      backgroundImage: 'url(/images/bg-1.jpg)',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      minHeight: '100vh'
    }}>
      <div>
        <br />
        <br />
      <Container style={{ paddingTop: 20, backgroundColor: "white" }}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Registration for Vitasmart
        </Typography>
        <Typography variant="caption">
          Note: After the registration the Administrator need to activate your
          account. Fillup the form and click on "Submit" to register.
        </Typography>
        <Alert
          banner
          type="info"
          message={
            <>
              <strong>Important !!</strong>, you must need a sponsor ID to
              complete the registration. Your accout will be activated after the
              admin's approval.

              <br />
              <br />
              If you have already registered then you can login using your User ID and Password. &nbsp;&nbsp;
              <Button type="primary" href={`/login`}>Login now</Button>
            </>
          }
        />

        <br />

        <div
          style={{
            backgroundColor: "#f9f9f9",
            paddingTop: 20,
            paddingBottom: 20,
            marginBottom: 20,
            padding: 20,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={3} md={3}>
              <div>
                Sponsor ID <span style={{ color: "red" }}>*</span>
              </div>
              <div className="error-msg">{error.sponsor_id}</div>
              <Input
                placeholder="Sponsor ID"
                value={sponsor_id}
                disabled={sponsorFound}
                onChange={(e) => setSponsorId(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} lg={3} md={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{
                    marginTop: 30,

                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  disabled={sponsorFound}
                  loading={sponsorIdSubmitting}
                  onClick={() => searchSponsorId()}
                  type="primary"
                >
                  <SearchOutlined /> &nbsp; Search
                </Button>
                &nbsp;
                <Button
                  style={{
                    marginTop: 30,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => clearSponsorId()}
                  type="primary"
                >
                  Clear Result
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              {sponsorMessage && sponsorMessage.status == false && (
                <Result
                  status="error"
                  title={sponsorMessage?.status_text}
                  subTitle="Try with new sponsor id"
                ></Result>
              )}

              {sponsorMessage && sponsorMessage.status == true && (
                <Result
                  status="success"
                  title={sponsorMessage?.status_text}
                  subTitle={`You will be registered under ${sponsorMessage?.data?.name} [${sponsorMessage?.data?.user_id}]`}
                ></Result>
              )}
            </Grid>
          </Grid>
        </div>

        <br />
        <br />

        {sponsorFound && (
          <div style={{backgroundColor: "white", padding: 20}}>
            <Typography variant="h6" gutterBottom>
              Basic Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Full Name <span style={{ color: "red" }}>*</span>
                </div>
                <div className="error-msg">{error.name}</div>
                <Input
                  placeholder="Full Name"
                  value={state.name}
                  onChange={(e) => onChange("name", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  PAN Number <span style={{ color: "red" }}>*</span>
                </div>
                <div className="error-msg">{error.PAN}</div>
                <Input
                  placeholder="PAN Number"
                  value={state.PAN}
                  onChange={(e) => onChange("PAN", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Address 
                </div>
                <div className="error-msg">{error.address}</div>
                <Input
                  placeholder="Address"
                  value={state.address}
                  onChange={(e) => onChange("address", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  City 
                </div>
                <div className="error-msg">{error.city}</div>
                <Input
                  placeholder="City"
                  value={state.city}
                  onChange={(e) => onChange("city", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  State 
                </div>
                <div className="error-msg">{error.state}</div>
                <Input
                  placeholder="State"
                  value={state.state}
                  onChange={(e) => onChange("state", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  PIN 
                </div>
                <div className="error-msg">{error.pin}</div>
                <Input
                  placeholder="Pin Code"
                  value={state.pin}
                  onChange={(e) => onChange("pin", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Date Of Birth 
                </div>
                <div className="error-msg">{error.dob}</div>
                <Input
                  type="text"
                  placeholder="Date of Birth"
                  value={state.dob}
                  onChange={(e) => onChange("dob", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Marital Status
                </div>
                <div className="error-msg">{error.m_status}</div>

                <Select
                  style={{ width: "100%" }}
                  value={state.m_status}
                  onChange={(e) => onChange("m_status", e)}
                >
                  <Option value="">Select</Option>
                  <Option value="MARRIED">MARRIED</Option>
                  <Option value="UNMARRIED">UNMARRIED</Option>
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Father's/Husband's Name{" "}
                  
                </div>
                <div className="error-msg">{error.father_name}</div>
                <Input
                  placeholder="Father's Name"
                  value={state.father_name}
                  onChange={(e) => onChange("father_name", e.target.value)}
                />
              </Grid>
            </Grid>

            <br />

            <Typography variant="h6" gutterBottom>
              Contact Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Mobile Number <span style={{ color: "red" }}>*</span>
                </div>
                <div className="error-msg">{error.mno}</div>
                <Input
                  placeholder="Mobile number"
                  value={state.mno}
                  onChange={(e) => onChange("mno", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>Email</div>
                <div className="error-msg">{error.email}</div>
                <Input
                  placeholder="Email address"
                  value={state.email}
                  onChange={(e) => onChange("email", e.target.value)}
                />
              </Grid>
            </Grid>

            <br />

            <Typography variant="h6" gutterBottom>
              Nominee Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Nominee Name 
                </div>
                <div className="error-msg">{error.nominee_name}</div>
                <Input
                  placeholder="Nominee Name"
                  value={state.nominee_name}
                  onChange={(e) => onChange("nominee_name", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Nominee Relation 
                </div>
                <div className="error-msg">{error.nominee_relation}</div>
                <Input
                  placeholder="Nominee Relation (e.g. Son/Daughter/Husband etc.)"
                  value={state.nominee_relation}
                  onChange={(e) => onChange("nominee_relation", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Nominee Date Of Birth 
                </div>
                <div className="error-msg">{error.nominee_dob}</div>
                <Input
                  type="text"
                  placeholder="Nominee Date of Birth"
                  value={state.nominee_dob}
                  onChange={(e) => onChange("nominee_dob", e.target.value)}
                />
              </Grid>
            </Grid>

            <br />
            <Typography variant="h6" gutterBottom>
              Account Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Account Holder Name <span style={{ color: "red" }}>*</span>
                </div>
                <div className="error-msg">{error.account_holder_name}</div>
                <Input
                  placeholder="Account Holder's Name"
                  value={state.account_holder_name}
                  onChange={(e) =>
                    onChange("account_holder_name", e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Bank <span style={{ color: "red" }}>*</span>
                </div>
                <div className="error-msg">{error.bank}</div>
                <Input
                  placeholder="Bank Name"
                  value={state.bank}
                  onChange={(e) => onChange("bank", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Branch<span style={{ color: "red" }}>*</span>
                </div>
                <div className="error-msg">{error.branch}</div>
                <Input
                  placeholder="Branch Name"
                  value={state.branch}
                  onChange={(e) => onChange("branch", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Account No<span style={{ color: "red" }}>*</span>
                </div>
                <div className="error-msg">{error.account_no}</div>
                <Input
                  placeholder="Account Number"
                  value={state.account_no}
                  onChange={(e) => onChange("account_no", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  IFSC Code<span style={{ color: "red" }}>*</span>
                </div>
                <div className="error-msg">{error.ifsc_code}</div>
                <Input
                  placeholder="IFSC Code"
                  value={state.ifsc_code}
                  onChange={(e) => onChange("ifsc_code", e.target.value)}
                />
              </Grid>
            </Grid>

            <br />
            <Typography variant="h6" gutterBottom>
              Tree Position
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={3} md={3}>
                <div>
                  Position<span style={{ color: "red" }}>*</span>
                </div>
                <div className="error-msg">{error.position}</div>
                <Select
                  style={{ width: "100%" }}
                  value={state.position}
                  onChange={(e) => onChange("position", e)}
                >
                  <Option value="">Select</Option>
                  <Option value="LEFT">LEFT</Option>
                  <Option value="RIGHT">RIGHT</Option>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" loading={isSubmitting} type="primary" onClick={onSubmit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        
      </Container>
      <br />
      <br />
      </div>
    </div>
  );
}
