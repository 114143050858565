import React, { Component } from "react";
import "../css/Login.css";
import Notifications, { notify } from "react-notify-toast";
import AuthService from "./AuthService";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Fade from "@material-ui/core/Fade";
import { Alert, Input } from "antd";

import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Typography,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  Divider,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",

    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundImage: "url(/images/bg-1.jpg)",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  rightSide: {
    flexBasis: "50%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  leftSide: {
    flexBasis: "50%",
    backgroundColor: blue[500],
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  },
  loginDiv: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    padding: "20px",
    borderRadius: "2%",
    backgroundColor: "white",
  },
  content: {
    position: "relative",
  },
  button: {
    backgroundColor: "#2196F3",
  },
  inputText: {
    padding: "15px",
    paddingLeft: "25px",
    border: "solid thin #F9F9F9",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "30px",
    backgroundColor: "#F9F9F9",
    marginBottom: "20px",
    outline: "none",
    boxShadow: "1px 2px 5px rgba(0,0,0,0.3  )",
  },
  loginText: {
    fontSize: 25,
    fontWeight: 400,
  },
  labelText: {
    fontSize: 15,
    marginBottom: 10,
    color: blue[600],
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.Auth = new AuthService();
  }
  componentWillMount() {
    if (this.Auth.loggedIn()) this.props.history.replace("/");
  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    this.Auth.login(this.state.username, this.state.password)
      .then((res) => {
        let username = this.state.username;
        this.setState({
          username: "",
          password: "",
          isLoading: false,
        });

        /* this.props.history.push({
                    state: { username: username, role: res.user.role },
                    pathname: '/'
                }); */
        //window.location.href="https://distributor.1hornfashion.com"
        window.location.reload();
      })
      .catch((err) => {
        if (err === "notactive") {
          notify.show("Your account is not activated.", "error", 3000);
          this.setState({
            isLoading: false,
          });
        } else {
          notify.show("Wrong Username or password", "error", 3000);
          this.setState({
            isLoading: false,
          });
        }
      });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Notifications />
        <section className={classes.container}>
          <div className={classes.rightSide}>
            <Fade in={true} timeout={{ enter: 1500 }}>
              <section className={classes.loginDiv}>
                <div align="center">
                  <br />
            
                  <Typography variant="h4" style={{fontWeight: 500}} gutterBottom>LOGIN</Typography>
                  <Alert
                    banner
                    style={{textAlign: "left"}}
                    type="warning"
                    message={`User your Associate Id and password to login. Remember, you can login after your account is active. Feel free to contact the administrator incase you encounter any difficulties login to this portal.`}
                  />
                </div>

                <br />

                <div>
                  <form onSubmit={this.onSubmit}>
                    <div className={classes.labelText}>
                      User ID / Associate ID{" "}
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <FormControl fullWidth required>
                      <Input
                        required
                        name="username"
                        value={this.state.username}
                        onChange={this.handleChange}
                        placeholder="Associate ID"
                      />
                    </FormControl>

                    <br />
                    <br />

                    <div className={classes.labelText}>
                      Password <span style={{ color: "red" }}>*</span>
                    </div>
                    <FormControl fullWidth required>
                      <Input
                        required
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        placeholder="Your password here"
                      />
                    </FormControl>

                    <br />
                    <br />

                    <div style={{ textAlign: "right" }}>
                      <Link to="/forgotpassword">
                        <Typography variant="caption">
                          Forgot password ?
                        </Typography>
                      </Link>
                    </div>

                    <br />

                    <div align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        className={classes.button}
                      >
                        Login
                      </Button>
                    </div>
                  </form>
                </div>
                <br />
                <br />
              </section>
            </Fade>
          </div>

          <div className={classes.leftSide}>
            <LoginTable />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Login);

export const IconLogin = (props) => {
  let { classes } = props;
  return <div className={classes.iconlogin}></div>;
};

export const LoginTable = (props) => {
  let { classes } = props;

  return (
    <React.Fragment>
      <Fade in={true} timeout={{ enter: 1500 }}>
        <Card raised style={{ width: "350px" }}>
          <CardContent>
            <div style={{ textAlign: "center" }}>
              <img
                alt="vitasmart-100"
                src={`/log-website-vm.png`}
                style={{ width: "200px" }}
              />
            </div>
            <Divider />
            <Typography variant="h6" style={{ textAlign: "center" }}>
              Do you want to signup with us ?
            </Typography>
            <Typography variant="body2" style={{ textAlign: "center" }}>
              Click on the link below
            </Typography>

            <Alert
              banner
              type="info"
              message={
                <div>
                  You will need the following to register:
                  <br />
                  <ul>
                    <li>A Sponsor ID with available position</li>
                    <li>Name & Contact Details</li>
                    <li>Nominee Details</li>
                    <li>Your Bank Account</li>
                    <li>Your Pan Card</li>
                  </ul>
                </div>
              }
            />
            <Typography>
              <br />
            </Typography>
            <div style={{ textAlign: "center" }}>
              <Link to="/registration-new">
                <Button variant="contained" size="small" color="primary">
                  Register Now
                </Button>
              </Link>
            </div>
          </CardContent>
        </Card>
      </Fade>
    </React.Fragment>
  );
};
