import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import UsersLanding from '../Components/Users/UsersLanding';


class TreeContainer extends Component {
    constructor(props) {
        super(props);

        let us = JSON.parse(localStorage.getItem('user'));
        let username = us.user.email;
        let name = us.user.name;

        this.state = {
            username: username,
            apikey: us.user.api_key,
            name
        }

    }
    render() {
        return (
            <div>
                <Navbar
                    history={this.props.history}
                    username={this.state.username}
                    name={this.state.name}
                />
                <div className="main-body">

                <UsersLanding
                
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            

                </div>
            </div>
        )
    }
}

export default withAuth(TreeContainer);