import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid, OutlinedInput } from '@material-ui/core';
import Close from '@material-ui/icons/Close'
const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
        
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        }
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    },
    formControl: {
        width: '100%',
        margin: theme.spacing.unit
    },
    bottomButton: {
        padding: theme.spacing.unit
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: '',
            narration: '',
            send_date: '',
            user_id:'',
            sponsor_id:this.props.username
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getInitialState() {
        this.setState({
            amount: '',
            narration: '',
            send_date: '',
            user_id:'',
            sponsor_id:this.props.username
           
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.__getInitialState();
        }


    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.__getInitialState();
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;


        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);

        }

        this.__getInitialState();

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" style={{flexGrow: 1,}} >
                                {this.props.edit ? "Edit" : "Send Money"}
                            </Typography>
                            <IconButton onClick={this.props.handleClose}>
                                <Close style={{color: 'white'}} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <TextField
                                label="Date"
                                type="date"
                                variant="outlined"
                                name="send_date"
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    },
                                    shrink: true
                                }}
                                value={this.state.send_date}
                                onChange={this.onChange}
                                required
                            />

                            <TextField
                                label="Amount"
                                type="text"
                                variant="outlined"
                                name="amount"
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    },
                                    shrink: true
                                }}
                                value={this.state.amount}
                                onChange={this.onChange}
                                required
                            />

                            <TextField
                                label="Purpose"
                                type="text"
                                variant="outlined"
                                name="narration"
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    },
                                    shrink: true
                                }}
                                value={this.state.narration}
                                onChange={this.onChange}
                                required
                            />
                                
                                <TextField
                                label="Send To"
                                type="text"
                                variant="outlined"
                                name="user_id"
                                className={classes.textField}
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    },
                                    shrink: true
                                }}
                                placeholder="User Id"
                                value={this.state.user_id}
                                onChange={this.onChange}
                                disabled={this.props.edit ? true : false}
                            />

                            <div className={classes.bottomButton}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {/* {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null} */}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)