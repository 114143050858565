import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@material-ui/core";
import Launch from "@material-ui/icons/Launch";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Print from "@material-ui/icons/Print";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import { lightGreen, red } from "@material-ui/core/colors";

export default class IncomeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoaded: false,
    };
  }

  handleDelete(rowsDeleted) {
    console.log(rowsDeleted);
  }

  onActionClick(el) {
    this.props.actionClick(el);
  }

  onPrintClick(id) {
    this.props.onPrintClick(id);
  }

  onDeleteClick(id) {
    this.props.onDeleteClick(id);
  }

  onClick() {
    console.log("hey");
  }

  getType(value) {
    if (value === 1) {
      return "FRESH";
    } else if (value === 2) {
      return "LEVEL UPDATE";
    } else if (value === 3) {
      return "RE-PURCHASE";
    } else if (value === 3) {
      return "RETAIL";
    }
  }

  getStatus(value) {
    if (value === 1) {
      return <Done style={{ color: lightGreen[300] }} />;
    } else {
      return <Close style={{ color: lightGreen[300] }} />;
    }
  }


  getAmount = (dr, cr) => {
    if(dr != 0){
        return <div style={{color: "green", fontWeight: 500}}>+ ₹ {dr}</div>;
    }

    if(cr != 0){
        return <div style={{color: "red", fontWeight: 500}}>- ₹ {cr}</div>;
    }


    if(dr == 0 && cr == 0){
        return 0;
    }
  }

  render() {
    const columns = [
      {
        name: "Date",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Particulars",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Amount ₹",
        options: {
          filter: true,
          sort: true,
        },
        
      },
    //   {
    //     name: "Dr ₹",
    //     options: {
    //       filter: true,
    //       sort: true,
    //     },
    //   },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: false,
      responsive: "scroll",
    };

    let data = [];
    let table_data = this.props.table_data;
    if (table_data.length > 0) {
      data = table_data.map((el, index) => [
        el.entry_date,
        el.parti,
        this.getAmount(el.dr, el.cr),
        //el.cr,
      ]);
    } else {
      data = [];
    }

    return (
      <div>
        <MUIDataTable
          title={"Income Table"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    );
  }
}
