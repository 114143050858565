import React, { Component } from "react";
import withAuth from "./../auth_components/js/withAuth";
import Navbar from "../Components/Navbar";
import "./css/homepage.css";
import Welcome from "../Components/Homepage/Welcome";
import ChangePassword from "../Components/Settings/ChangePassword";
import SmallBread from "../Components/SmallBread";

class SettingsContainer extends Component {
  constructor(props) {
    super(props);

    let us = JSON.parse(localStorage.getItem("user"));
    let username = us.user.email;
    let id = us.user.id;
    let name = us.user.name;

    this.state = {
      username: username,
      apikey: us.user.api_key,
      id: id,
      name,
    };
  }
  render() {
    return (
      <div>
        <Navbar
          history={this.props.history}
          username={this.state.username}
          name={this.state.name}
          apikey={this.state.apikey}
        />
       
        <div className="main-body">
        <SmallBread
          data={[
            {
              title: "Profile",
              link: "/profile",
            },
            {
              title: "View",
              link: "",
            },
          ]}
        />
          <ChangePassword
            history={this.props.history}
            username={this.state.username}
          />
        </div>
      </div>
    );
  }
}

export default withAuth(SettingsContainer);
