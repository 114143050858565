import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import { GetData } from '../api/service';

class HomePage extends Component {
  constructor(props) {
    super(props);

    let us = JSON.parse(localStorage.getItem('user'));
    let username = us.user.email;
    let name = us.user.name;

    this.state = {
      username: username,
      apikey: us.user.api_key,
      name,
      user_data: [],
      isDataLoaded: false

    }

  }

  componentDidMount() {
    GetData(`/${this.state.apikey}/${this.state.username}/${this.state.username}/getusersingle`)
      .then((resp) => {

        if (Array.isArray(resp) && resp.length > 0) {
          this.setState({
            user_data: resp[0],
            isDataLoaded: true
          })
        }
      })
  }
  render() {
    return (
      <div>
        <Navbar
          history={this.props.history}
          username={this.state.username}
          name={this.state.name}
        />
        <div className="main-body-no-padding">

          <Welcome
            history={this.props.history}
            name={this.props.name}
            username={this.state.username}
            apikey={this.state.apikey}
            isDataLoaded={this.state.isDataLoaded}
            user_data={this.state.user_data}
          />

        </div>
      </div>
    )
  }
}

export default withAuth(HomePage);